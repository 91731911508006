.navbar {
  width: 100%;
  height: 100px;
  background: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: height 0.3s ease;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.navbar a, .resumeLink {
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  font-size: 18px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar a:hover, .resumeLink:hover {
  color: #808080;
  transform: translateY(-2px);
}

.resumeLink {
  border: 2px solid white; /* Highlighted outline for the resume link */
  border-radius: 5px; /* Rounded corners for a modern look */
  padding: 5px 15px; /* Adjusted padding for better visual balance */
}

.toggleButton {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  display: none;
  transition: transform 0.3s ease;
}

.toggleButton.active {
  transform: rotate(90deg);
}

.toggleButton svg {
  font-size: 30px;
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: block;
  }

  .navbar a, .resumeLink {
    display: none;
  }

  .navbar.expanded a, .navbar.expanded .resumeLink {
    display: block;
    text-align: center;
    padding: 10px 0;
  }

  #open .links {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
