@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600; 700;800;9006display=swap');


/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
} 

body{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #333333;
} */

.experience {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tag-filter-container {
    display: flex;
    justify-content: center;
    width: 100%; /* Ensures the container spans the full width */
  }
  
  .experience h3{
    color:black;
  }
  .experience h4 {
    color:black;
  }
  
  .experience p{
    color:black;
  }

  .experience span{
color: white;
  }
  
.expcontainer {
    position: relative;
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-wrap: wrap;
    gap: 100px 50px;
    padding: 100px 50px;
    
}

.projcontainer{
    position: relative;
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-wrap: wrap;
    gap: 100px 50px;
    padding: 100px 50px;
    
}

.expcontainer .custom-card { 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    width: 350px; 
    max-width: 100%; 
    height: 310px;
    background: #FFF; 
    border-radius: 20px;
    transition: 0.5s;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.expcontainer .custom-card:hover {
    height: 380px;
}
.expcontainer .custom-card .img-box {
    position: absolute;
    top: 20px;
    width: 300px; 
    height: 220px; 
    background: #333; 
    border-radius: 12px; 
    overflow: hidden; 
    transition: 0.5s;
}
.expcontainer .custom-card:hover .img-box {
    top: -100px; 
    scale: 0.75;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);

    }
.expcontainer .custom-card .img-box img {
    position: absolute;
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
    object-fit:cover;
}
.expcontainer .custom-card .custom-content {
    position: absolute;
    top: 252px; 
    width: 100%;
    height: 60px;
    padding: 0 30px; 
    text-align: center;
    overflow: hidden; 
    transition: 0.5s;
}

    .expcontainer .custom-card:hover .custom-content {
        top: 130px;
        height: 250px;
    }
    .expcontainer .custom-card .custom-content h2 {
        font-size: 1.5rem; 
        font-weight: 700; 
        color: #333333;
    }
    .expcontainer .custom-card .custom-content p {
        color: #333
    }
    .expcontainer .custom-card .custom-content button {
        position: relative; 
        top: 15px;
        display: inline-block;
        padding: 12px 25px; 
        text-decoration: none; 
        background: #181818;
        color: #fff; 
        font-weight: 500;
    }
    .expcontainer .custom-card .custom-content a:hover {
        opacity: 0.8;
    }
    @media (max-width: 480px) {
        .expcontainer .custom-card{
        width: 230px; 
        border-radius: 15px;
        }
        .expcontainer .custom-card .img-box {
        width: 185px; 
        border-radius: 10px;
        }
        .expcontainer .custom-card .custom-content p{
        font-size: 0.8rem;
        }
        
        .expcontainer .custom-card .custom-content a {
        font-size: 0.9rem;
        }
    }