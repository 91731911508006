@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');


.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #000000;
  overflow: hidden;
  font-family: 'Roboto Mono';
}

body {
  padding: 0;
  margin: 0;
}

:root {
	font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
	color-scheme: light dark;
	--box-background: linear-gradient(120deg,
			rgb(255 255 255 / 12%),
			rgb(255 255 255 / 1%));
	--box-background-before: linear-gradient(120deg,
			rgba(255, 255, 255, 0.3),
			rgba(255, 255, 255, 0.05));

	/* fontsizes */
	--h1-fs: 36px;
	--h2-fs: 30px;
	--h4-fs: 14px;
	--h3-fs: 20px;
	--h5-fs: 16px;

	/*colors*/
	--text-color-heading: #ffffff;
	--text-color-blue: #5b78f6;
	--background-color: #0f0f0f;
	--default-text-color: #9f9f9f;
	--button-color: #323232;

	// constants
	--default-padding-inside-box: 30px;
	--box-border-radius: 30px;
	--section-spacing-bottom: 20px;
	--nav-bottom-spacing: 80px;
	--container-max-width: 1170px;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
	list-style: none;
}

// body {
// 	background: var(--background-color);
// 	color: var(--default-text-color);
// 	line-height: 19.2px;
// 	font-size: 14px;
// 	font-weight: 400;
// 	text-transform: capitalize;
// }

.container {
	max-width: var(--container-max-width);
	margin: 0 auto;

	@media(max-width:991px) {
		max-width: 90%;
	}
}

.section {
	width: 100%;
}

/* typography */

h1 {
	font-size: var(--h1-fs);
	line-height: 40px;
	margin-bottom: 12px;
	color: var(--text-color-heading);
}

h2 {
	font-size: var(--h2-fs);
	font-weight: bold;
	color: var(--text-color-heading);

}

h3 {
	font-size: var(--h3-fs);
	margin-bottom: 12px;
	color: var(--text-color-heading);

	@media(max-width:480px) {
		font-size: 16px;
	}
}

h4 {
	font-size: var(--h4-fs);
	margin-bottom: 3px;
	color: #bcbcbc;
}

h5 {
	font-size: var(--h5-fs);

	&:hover {
		color: var(--text-color-heading);
		transition: 0.3s;
	}
}

a {
	color: var(--default-text-color);
}

.section {
	padding: 60px 0 0 0;
}

img {
	width: 100%;
}

// utility classes


.mb-10 {
	margin-bottom: 10px;
}

.mb-20 {
	margin-bottom: 20px;
}

.w-100 {
	width: 100%;
}

// utility classes ends here

.card {
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--background-color);
	border-radius: var(--box-border-radius);
	position: relative;
	padding: 30px;
	

	&.row {
		flex-direction: row;

		@media(max-width:480px) {
			flex-direction: column;
		}
	}

	&.column {
		flex-direction: column;
	}

	.image {
		img {
			width: 100%;
		}

		min-width: 200px;
		min-height: 120px;
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: var(--box-background-before);
		border-radius: 30px;
		opacity: 0.25;
	}

	&::after {
		content: '';
		background: var(--box-background);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		height: 100%;
		border-radius: var(--box-border-radius);
	}

	.icon {
		position: absolute;
		right: 20px;
		bottom: 20px;
		opacity: 0.2;
		transition: .3s;
	}

	.info {
		width: 100%;
		margin-top: 20px;
	}

	&:hover {
		.icon {
			opacity: 1;
		}
	}
}

.button {
	padding: 10px 15px;
	background-color: var(--button-color);
	color: var(--text-color-heading);
	border-radius: 10px;
	border: none;
	outline: none;
	transition: 0.3s;
	font-size: 18px;
	cursor: pointer;

	&:hover {
		background-color: var(--text-color-heading);
		color: var(--background-color);
	}
}