.profile-card .right {
    .socialmedia {
        display: flex;
        margin-bottom: 10px;
        

        .card {
            margin-right: 10px;
        }
    }
}