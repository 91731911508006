.projectContainers {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1100px;
  margin: 40px auto;
  padding: 20px;
  color: #ffffff;
  background: #333;
  border-radius: 8px;
}

.projectVisuals {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projectImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.projectLinks {
  margin-top: 20px;
}

.githubIcon {
  font-size: 4rem;
  color: #ffffff;
}

.projectInfo {
  text-align: center;
  margin-top: 20px;
}

.projectInfo h1 {
  margin-bottom: 10px;
  font-size: 2.5rem;
}

.projectInfo h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: normal;
}

.projectBlurbs p {
  font-size: 1rem;
  margin-bottom: 15px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .projectContainer {
    flex-direction: column;
  }

  .projectVisuals, .projectInfo {
    width: 100%;
  }

  .projectInfo h1, .projectInfo h2 {
    font-size: 1.5rem;
  }
}
