.info-card .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
	
}

.card {
	display: flex;
	
	justify-content: center;
	align-items: center;
	background: var(--background-color);
	border-radius: var(--box-border-radius);
	position: relative;
	padding: 30px;

	&.row {
		flex-direction: row;

		@media(max-width:480px) {
			flex-direction: column;
		}
	}

	&.column {
		flex-direction: column;
	}

	.image {
		img {
			width: 100%;
		}

		min-width: 200px;
		min-height: 120px;
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: var(--box-background-before);
		border-radius: 30px;
		opacity: 0.25;
	}

	&::after {
		content: '';
		background: var(--box-background);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		height: 100%;
		border-radius: var(--box-border-radius);
	}

	.icon {
		position: absolute;
		right: 20px;
		bottom: 20px;
		opacity: 0.2;
		transition: .3s;
	}

	.info {
		width: 100%;
		margin-top: 20px;
	}

	&:hover {
		.icon {
			opacity: 1;
		}
	}
}